import {ApolloClient, ApolloLink, from, InMemoryCache} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';
import {BatchHttpLink} from '@apollo/client/link/batch-http';

const httpLink = new BatchHttpLink({
    uri: '/graphql',

    // Equivalent to no batching
    batchMax: Number.MAX_SAFE_INTEGER,

    // Default interval is 10ms, which is an unnecessary latency - 1ms is the minimum allowed value
    batchInterval: 1,

    // Operations with the same batch key are sent together, different batch keys are sent separately
    batchKey: (operation) => operation.getContext().batchKey || 'default',
});

const languageLink = new ApolloLink((operation, forward) => {
    if (window.config.language ?? false) {
        // Replace browser's `Accept-Language` with application's currently selected language.
        operation.setContext({
            headers: {
                'Accept-Language': window.config.language,
            },
        });
    }
    return forward(operation);
});

// TODO share among all components?
const cache = new InMemoryCache({
    typePolicies: {
        EmailMessage: {
            keyFields: ['internalId'],
        },
        Query: {
            fields: {
                // relayStylePagination function generates a field policy with a read function that simply returns all available data from cache, allowing for "load more" functionality with `fetchMore`. When multiple pages have to be shown, `relayStylePagination` cannot be used.
                paymentMethods: relayStylePagination(),
                serviceSetups: relayStylePagination(),
            },
        },
        RoomSetup: {
            fields: {
                activity: relayStylePagination(['filter']),
            },
        },
        Settings: {
            keyFields: [],
        },
        FiskalyFON: {
            keyFields: [],
        },
    },
});

export const apollo = new ApolloClient({
    link: from([languageLink, httpLink]),
    cache: cache,
});
