import FilterChipList from './FilterChiplist';
import AddFilters from './AddFilters';
import {translate} from '../../utils';
import {filtersMap} from './FiltersUtils';
import {useState} from 'react';

export default function FilterSelection({schema, filter, suggestedFieldNames, applyFilters, clearBtn = true}) {
    const [filterIteration, setFilterIteration] = useState(0); // When needed, bust component state on filter change
    const internalFilter = transformFromApi(filter, schema);
    const activeFieldNames = Object.keys(filter).filter(fieldName => internalFilter.hasOwnProperty(fieldName));

    function applyFilter(fieldName, internalOperators) {
        const newInternalFilter = {};

        for (let activeFieldName of activeFieldNames) {
            if (activeFieldName !== fieldName) {
                newInternalFilter[activeFieldName] = internalFilter[activeFieldName];
            } else {
                if (internalOperators !== null) {
                    newInternalFilter[fieldName] = internalOperators;
                }
            }
        }

        if (!activeFieldNames.includes(fieldName)) {
            newInternalFilter[fieldName] = internalOperators;
        }

        applyFilters(transformToApi(newInternalFilter, schema));
        setFilterIteration(i => ++i);
    }

    function clearFilters() {
        applyFilters({});
        setFilterIteration(i => ++i);
    }

    const hasActiveFilter = activeFieldNames.length > 0;
    const unusedFieldNames = Object.keys(schema).filter(fieldName => !suggestedFieldNames.includes(fieldName) && !activeFieldNames.includes(fieldName));

    return <>
        <div className="flex gap-1 flex-wrap w-full">
            <FilterChipList applyFilter={applyFilter} schema={schema} suggestedFieldNames={suggestedFieldNames}
                            activeFieldNames={activeFieldNames} internalFilter={internalFilter}/>

            {unusedFieldNames.length > 0 && <AddFilters key={filterIteration} applyFilter={applyFilter} schema={schema} unusedFieldNames={unusedFieldNames}
                                                        internalFilter={internalFilter}/>}
        </div>

        {hasActiveFilter && clearBtn && <button type="button" className="btn btn-danger-clear" onClick={clearFilters}>
            <i className="fa fa-times"></i> {translate('frontend', 'FILTER_SELECTION_CLEAR_FILTERS')}
        </button>}
    </>;
}

/**
 * Internal <FilterSelection/> filter shape is different from the provided argument.
 */
function transformFromApi(filter, schema) {
    const internalFilter = {};

    for (const fieldName of Object.keys(schema)) {
        if (schema[fieldName].type in filtersMap) {
            internalFilter[fieldName] = filtersMap[schema[fieldName].type].filterOptions.defaultFilter(schema[fieldName]);
        } else {
            console.error(`unrecognised type: ${schema[fieldName].type}`);
        }
    }

    for (const [fieldName, operators] of Object.entries(filter)) {
        // Filtering by a field that is not defined
        if (!(fieldName in schema)) continue;


        const internalOperators = filtersMap[schema[fieldName].type].filterOptions.transformFromApi(
            operators,
            schema[fieldName],
        );
        if (!internalOperators) {
            // Unsupported filter
            continue;
        }

        internalFilter[fieldName].setting = internalOperators.setting;
        Object.assign(internalFilter[fieldName].options, internalOperators.options);
    }

    return internalFilter;
}

function transformToApi(internalFilter, schema) {
    const filter = {};
    for (const [fieldName, internalOperators] of Object.entries(internalFilter)) {
        // Filtering by a field that is not defined
        if (!(fieldName in schema)) continue;

        const operators = filtersMap[schema[fieldName].type].filterOptions.transformToApi(internalOperators);
        if (!operators) {
            // No value selected
            continue;
        }

        filter[fieldName] = operators;
    }

    return filter;
}
