export default function EnumFilter({fieldSchema, internalOperators, setInternalOperators}) {
    function handleChange(option, event) {
        setInternalOperators({
            options: {
                in: {
                    ...internalOperators.options.in,
                    [option]: event.target.checked,
                },
            },
            setting: internalOperators.setting,
        });
    }

    const options = [];
    for (const [option, checked] of Object.entries(internalOperators.options.in)) {
        options.push(
            <div key={option} className={'checkbox'}>
                <label>
                    <input type={'checkbox'} checked={checked} onChange={event => handleChange(option, event)}/>
                    {fieldSchema.values[option].label}
                </label>
            </div>,
        );
    }

    return <div style={{maxHeight: 'max(50vh, 340px)', overflowY: 'auto'}}>
        {options}
    </div>;
}

export const filterOptions = Object.freeze({
    type: 'enum',
    transformFromApi: (operators, fieldSchema) => {
        const valueMap = {};
        for (let option of Object.keys(fieldSchema.values)) {
            valueMap[option] = false;
        }
        for (const value of Array.isArray(operators.in) ? operators.in : Object.values(operators.in || {})) {
            if (value in fieldSchema.values) {
                valueMap[value] = true;
            }
        }

        if (Object.keys(valueMap).length === 0) return null;

        return {
            options: {
                in: valueMap,
            },
            setting: 'in',
        };
    },
    transformToApi: internalOperators => {
        const selectedValues = [];
        for (const [name, checked] of Object.entries(internalOperators.options.in)) {
            if (checked) {
                selectedValues.push(name);
            }
        }

        return {in: selectedValues};
    },
    displayValue: (internalOperators, fieldSchema) => {
        const selectedValues = [];
        for (const [name, checked] of Object.entries(internalOperators.options.in)) {
            if (checked) {
                selectedValues.push(name);
            }
        }

        if (fieldSchema.displayValue) {
            return fieldSchema.displayValue(selectedValues);
        } else {
            return selectedValues.map(value => fieldSchema.values[value].label).join(',');
        }
    },
    defaultFilter: fieldSchema => {
        const valueMap = {};
        for (let option of Object.keys(fieldSchema.values)) {
            valueMap[option] = false;
        }

        return {
            options: {
                in: valueMap,
            },
            setting: 'in',
        };
    },
});
